<template>
    <div style="min-height: 100vh;overflow: scroll;">
        <div class="top_bg" style="background-image: url('img/starPlan/main_bg.png');">

            <div class="play_tips_container">
                <div class="play_tips_title" style="background-image: url('img/starPlan/play_tips_title.png');"></div>
                <div class="play_tips"> 星动计划，助力造星，每天主播的星动魅力值越高，对应的房间推荐位将会更靠前；收到任意用户送出的星动礼物，即可获得活动星动魅力值。</div>
            </div>
            <div class="main_container">
                <div class="detail_tips_title" style="background-image: url('img/starPlan/detail_tips_title.png');"></div>
                <div class="play_tips">1：送出星动礼物，收到的用户会获得星动礼物，星动礼物如下：</div>
                <div class="bronze_bag">
                    <div class="tx_title" style="background-image: url('img/starPlan/tx_title.png');"></div>
                    <div class="gift_box">
                        <div v-for="(item, index) in info1" :key="index">
                            <div class="gift_item" style="background-image: url('img/starPlan/gold_egg_bg.png');">
                                <div class="sub_bg">
                                    <img class="gold" src="img/bi.png" alt="">
                                    <span class="value">{{ item.diamonds }}</span>
                                </div>
                                <img class="gift_img" :src="item.gift_url" alt="">
                                <img class="sub_bottom" v-if="item.level == 1" src="img/starPlan/low.png" alt="">
                                <img class="sub_bottom" v-if="item.level == 2" src="img/starPlan/mid.png" alt="">
                                <img class="sub_bottom" v-if="item.level == 3" src="img/starPlan/high.png" alt="">
                            </div>
                            <div class="gift_name">{{ item.gift_name }}</div>
                        </div>

                    </div>
                </div>
                <div class="gold_bag">
                    <div class="tx_title" style="background-image: url('img/starPlan/mars_title.png');"></div>
                    <div class="gift_box">
                        <div v-for="(item, index) in info2" :key="index">
                            <div class="gift_item" style="background-image: url('img/starPlan/gold_egg_bg.png');">
                                <div class="sub_bg">
                                    <img class="gold" src="img/bi.png" alt="">
                                    <span class="value">{{ item.diamonds }}</span>
                                </div>
                                <img class="sub_bottom" v-if="item.level == 1" src="img/starPlan/low.png" alt="">
                                <img class="sub_bottom" v-if="item.level == 2" src="img/starPlan/mid.png" alt="">
                                <img class="sub_bottom" v-if="item.level == 3" src="img/starPlan/high.png" alt="">
                                <img class="gift_img" :src="item.gift_url" alt="">
                            </div>
                            <div class="gift_name">{{ item.gift_name }}</div>
                        </div>
                    </div>
                </div>
                <div class="play_tips_">2：星动礼物记录可在礼物收支中查看。</div>
            </div>
            <div class="play_tips_container_">
                <div class="play_tips_title" style="background-image: url('img/starPlan/specal_tips_title.png');"></div>
                <div class="play_tips">
                    该玩法仅供娱乐，用户不可将星动礼物反向兑换成现金或有价值商品，不得用于任何形式的营利活动。禁止一切线下交易、币商收币等第三方不当行为，平台将对上述交易行为进行严厉打击，用户需自行承担与任何第三方交易行为的不利后果。

                </div>
            </div>
            <div class="bottom_tips" style="background-image: url('img/starPlan/bottom_tips.png');"></div>
        </div>


    </div>
</template>
<script>
import "@/assets/css/starPlan.css"
import { starPlanInfo } from '@/api/api'
export default {
    data() {
        return {
            info1: [],
            info2: [],
            rate1: [],
            rate2: []
        }
    },
    created() {
        let access_token = this.$route.query.access_token
        localStorage.setItem('access_token', access_token)
        this.getInfo()
    },
    methods: {
        getInfo() {
            let params = {}
            starPlanInfo(params).then(res => {
                // console.log(res)
                this.info1 = res.data.data[0].gift_info
                this.info2 = res.data.data[1].gift_info
                this.rate1 = res.data.rate[0]
                this.rate2 = res.data.rate[1]

            })
        }
    }
}
</script>
 